import React from "react";

const Services = () => {
  return (
    <div className="middle">
      <div className="our-services-men">
        <div className="container">
          <div className="heading-men">
            <h2>Our Services</h2>
            <p>A Growing Community of Happy Customers.</p>
          </div>
          <div className="row mt-4">
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon1.png" alt="Icon" />
                <h2>One-Time Service</h2>
                <p>
                Perfect for when your car needs an instant refresh! This service includes a thorough cleaning of both the interior and exterior, leaving your vehicle looking and feeling like new.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon2.png" alt="Icon" />
                <h2>Monthly Services</h2>
                <p>
                Enjoy the convenience of regular car care with our monthly plans. Each package includes four interior cleaning sessions throughout the month, along with exterior detailing, ensuring your car stays spotless inside and out.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon3.png" alt="Icon" />
                <h2>Exterior-Only Service
                </h2>
                <p>
                Focused on the outside! Our exterior-only service includes a meticulous cleaning and detailing of your car’s exterior to restore its shine and protect its finish.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon4.png" alt="Icon" />
                <h2> Interior + Exterior Service</h2>
                <p>
                A comprehensive package! This service takes care of your car inside and out, leaving both the interior and exterior clean, refreshed, and ready to impress.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="At-fingertips-men">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12"
              data-aos="zoom-in"
              data-aos-duration={2000}
            >
              <div className="At-fingertips-left">
                <div className="heading-men p-0">
                  <img
                    className="app-icon-photo"
                    src="images/app-icon.png"
                    alt="App Icon"
                  />
                  <h2>At your doorstep, at your fingertips</h2>
                  <p className="pt-2">
                    Experience the most convenient, professional, and
                    eco-friendly car wash solution—just a tap away!
                  </p>
                  <div className="all-btn">
                    <div className="row mt-4 justify-content-center">
                      <div className="col-lg-2 play">
                        <div className="btn1 btn2">
                          <a href="https://play.google.com/store/apps/details?id=com.washwoosh.userapp&pcampaignid=web_share">
                            <img
                              className="img-fluid"
                              src="images/d-app1.png"
                            />
                          </a>
                        </div>

                        <div className="btn1 btn2">
                          <a href="https://apps.apple.com/in/app/washwoosh-car-care-app/id6738276020">
                            <img
                              className="img-fluid"
                              src="images/d-app2.png"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
