import React, { useEffect } from "react";

const Downloads = () => {
  useEffect(() => {
    const appStoreUrl =
      "https://apps.apple.com/in/app/washwoosh-the-car-care-app/id6738276020";
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.washwoosh.userapp&pcampaignid=web_share";
    function getPlatformDetails() {
      let OSName = "Unknown OS";
      if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
      if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
      if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
      if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";
      if (OSName === "MacOS") {
        window.location.href = appStoreUrl;
        return;
      } else {
        window.location.href = playStoreUrl;
        return;
      }
    }

    getPlatformDetails();
  }, []);
  return <div>Please wait while you are being redirected...</div>;
};

export default Downloads;
