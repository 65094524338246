import React from 'react'

const RefundPolicy = () => {
  return (
    <div>
        <div className="middle">
  <div className="privacy-men">
    <div className="container">
      <div className="heading-men">
        <h2>Cancellation and Refunds Policy</h2>
        <p>We understand that circumstances may arise that require you to cancel your service booking.<br />
          Please review our cancellation and refund policy below:</p>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-12 col-md-12">
          <div className="contact-right">
            <div className="working-hours-text privacy-text">
              <h2>Cancellation Policy</h2>
              <p>Subscriptions made can be revised/pulled back before the second availing of the service in which case INR.100/- shall be charged. For subscriptions, services availed more than once within the package shall be charged individually on instance of discontinuation along with the service/cancellation charges. You may cancel your hand-picked service booking without penalty up to 24 hours before the scheduled service time. Cancellations made within 24 hours of the scheduled service time shall be subjected to a cancellation fee of INR.100/- of the service charges.</p>
              <h2>Refund Eligibility</h2>
              <p>If you cancel your service booking within the eligible cancellation period, you may be entitled to a full refund of any payments made post deduction of the service charges. Refunds will be processed within 4 working days from the date of cancellation. Please keep one day as consideration for bank proceedings. For subscriptions, services availed more than once within the package shall be charged individually on instance of discontinuation along with the service/cancellation charges.</p>
              <h2>No-shows</h2>
              <p>Failure to cancel your service booking and not showing up for the scheduled appointment will result in forfeiture of any payments made. No refunds will be issued for no-shows. On rarest instances of WashWoosh no-shows, full amount shall be refunded to the original payment source.</p>
              <h2>Refund Method</h2>
              <p>Refunds will be issued using the same method of payment used for the original transaction. If payment was made by credit card, refunds will be issued to the same credit card. Refunds will be processed within 4 working days from the date of cancellation.</p>
              <h2>Contact Us</h2>
              <p>If you have any questions about our cancellation and refund policy, please contact us at <b>1800-2700-722</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      
    </div>
  )
}

export default RefundPolicy
