import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="middle">
      <div className="header-banner">
        <video
          className="bg-video"
          width="100%"
          height="550px"
          muted
          autoPlay
          playsinline="true"
          loop
          preload="auto"
          onloadstart="this.volume=0.2"
          style={{ objectFit: "cover" }}
        >
          <source src="images/movie.mp4" type="video/mp4" />
        </video>
        <div className="container">
          <div className="row banner-men">
            <div className="col-lg-6 col-md-6">
              <div className="banner-text">
                <h2>
                  <span>Clean </span> Convenience
                </h2>
                <p>
                  Get expert car washes at your doorstep with WashWoosh. We use
                  eco-friendly, branded products and advanced waterless
                  techniques to keep your car spotless and the environment safe.
                  Convenient, professional service—
                  <strong style={{ color: "#00978a", fontSize: "18px" }}>
                    Anytime, Anywhere
                  </strong>
                </p>
                <div className="all-btn">
                  <div className="row">
                    <div className="col-lg-3 playapp">
                      <div className="btn1">
                        <a href="https://play.google.com/store/apps/details?id=com.washwoosh.userapp&pcampaignid=web_share">
                          <img className="img-fluid" src="images/d-app1.png" />
                        </a>
                      </div>
                      <div className="btn1">
                        <a href="https://apps.apple.com/in/app/washwoosh-car-care-app/id6738276020">
                          <img className="img-fluid" src="images/d-app2.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div
                className="banner-text banner-text-one text-end"
                //   data-aos="fade-up"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration={2000}
                //   data-aos="fade-up"
                // data-aos-offset="200"
                // data-aos-delay="50"
                // data-aos-duration="1000"
                // data-aos-easing="ease-in-out"
                // data-aos-mirror="false"
                // data-aos-once="true"
              >
                <img
                  className="img-fluid"
                  src="images/banner-photo.png"
                  alt="Banner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-men">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div
                className="about-text"
                data-aos="fade-down"
                data-aos-duration={2000}
              >
                <h2>About Us</h2>
                <p className="welcome-text">
                  {/* <b>Welcome To WashWoosh</b> */}
                </p>
                <p>
                  At
                  <strong style={{ color: "#00978a", fontSize: "16px" }}>
                    {" "}
                    WashWoosh
                  </strong>
                  , we bring top-tier mobile car washing directly to your
                  doorstep, offering hassle-free, professional care for your
                  vehicle. Using premium, eco-friendly products, we ensure
                  exceptional results that are both effective and safe for the
                  environment. Our skilled team is committed to making your car
                  shine, all while you go about your day.
                </p>
                {/* <div class="book-mafia mt-4">
                  <Link to={"/aboutus"}>
                    <a className="btn head-btn">
                      <span>Read More ⇾</span>
                    </a>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className="banner-text banner-text-car text-end"
                data-aos="zoom-in-up"
                data-aos-duration={2000}
              >
                <img
                  className="img-fluid"
                  src="images/car-wash1.png"
                  alt="Photo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works">
      <div className="container">
          <div className="heading-men">
            <h2>How it works?</h2>
            <p>WashWoosh brings quality service to your doorstep with ease.</p>
          </div>
          <div className="row pt-4" data-aos="zoom-in" data-aos-duration={3000}>
            <div className="col-lg-4 col-md-4">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-one how-works-active">
                    <img
                      className="img-fluid"
                      src="images/Download-2.svg"
                      alt="Icon"
                    />
                    <h2>
                      <strong style={{ fontSize: "20px" }}>
                        Download the App
                      </strong>
                      <br /> Available on iOS and Android.
                    </h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "120px" }}
                >
                  <div className="how-works-one">
                    <img
                      className="img-fluid"
                      src="images/add car_3.svg"
                      alt="Icon"
                    />
                    <h2>
                      <strong style={{ fontSize: "20px" }}>Choose Your Service</strong>
                      <br />
                      Select from our list of services.
                    </h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "110px" }}
                >
                  <div className="how-works-one">
                    <img
                      className="img-fluid"
                      src="images/Payment_1_2.svg"
                      alt="Icon"
                    />
                    <h2>
                      <strong style={{ fontSize: "20px" }}>Confirm Your Subscription</strong> <br /> Sit back
                      and relax.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 modile-box1"
              style={{ position: "relative" }}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center">
                    <span>1</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-two">
                    <span>2</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-three">
                    <span>3</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-for">
                    <span>4</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-five">
                    <span>5</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-six">
                    <span>6</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="row">
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "150px" }}
                >
                  <div className="how-works-one how-works-rignt">
                    <img
                      className="img-fluid"
                      src="images/Mobile login3.svg"
                      alt="Icon"
                    />
                    <h2>
                      <strong style={{ fontSize: "20px" }}>Create Your Profile</strong>
                      <br />
                      Add your vehicle details.
                    </h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "100px" }}
                >
                  <div className="how-works-one how-works-rignt confirm-order">
                    <img
                      className="img-fluid"
                      src="images/Select-bro.svg"
                      alt="Icon"
                    />
                    <h2>
                      <strong style={{ fontSize: "20px" }}> Choose Your Payment</strong>
                      <br />
                      Select your preferred payment method.{" "}
                    </h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "100px" }}
                >
                  <div className="how-works-one how-works-rignt ">
                    <img
                      className="img-fluid"
                      src="images/Select-pana.svg"
                      alt="Icon"
                    />
                    <h2>
                      <strong style={{ fontSize: "20px" }}> We’re On Our Way!</strong>
                      <br />
                      Our experts will be there in no time.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="middle-banner">
        <div className="container-fluid p-0">
          <div
            className="middle-banner-text"
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration={2000}
          >
            <img
              className="img-fluid"
              src="images/middle-banner.png"
              alt="Banner"
            />
          </div>
        </div>
      </div>
      <div className="our-services-men">
        <div className="container">
          <div className="heading-men">
            <h2>Our Services</h2>
            <p>A Growing Community of Happy Customers.</p>
          </div>
          <div className="row mt-4">
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon1.png" alt="Icon" />
                <h2>One-Time Service</h2>
                <p>
                  Perfect for when your car needs an instant refresh! This
                  service includes a thorough cleaning of both the interior and
                  exterior, leaving your vehicle looking and feeling like new.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon2.png" alt="Icon" />
                <h2>Monthly Services</h2>
                <p>
                  Enjoy the convenience of regular car care with our monthly
                  plans. Each package includes four interior cleaning sessions
                  throughout the month, along with exterior detailing, ensuring
                  your car stays spotless inside and out.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon3.png" alt="Icon" />
                <h2>Exterior-Only Service</h2>
                <p>
                  Focused on the outside! Our exterior-only service includes a
                  meticulous cleaning and detailing of your car’s exterior to
                  restore its shine and protect its finish.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon4.png" alt="Icon" />
                <h2> Interior + Exterior Service</h2>
                <p>
                  A comprehensive package! This service takes care of your car
                  inside and out, leaving both the interior and exterior clean,
                  refreshed, and ready to impress.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-chooseus-men">
        <div className="container chooseus-text">
          <div className="heading-men">
            <h2>Why Choose Us?</h2>
            {/* <p>Thousands of satisfied customers and top-rated car maintenance services.</p> */}
          </div>
          <div className="row mt-4 align-items-center">
            <div
              className="col-lg-6 col-md-6"
              data-aos="fade-down"
              data-aos-duration={2000}
            >
              <div className="why-chooseus-one-photo">
                <img
                  className="img-fluid"
                  src="images/why-choose-us-photo.png"
                  alt="Why Choose Us Photo"
                />
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6"
              data-aos="zoom-in-up"
              data-aos-duration={2000}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="why-chooseus-one">
                    <img
                      className="img-fluid"
                      src="images/whyicon1.svg"
                      alt="Why Choose Us Photo"
                    />
                    <h2>
                      Expert {""}
                      <strong style={{ color: "#00978a", fontSize: "18px", fontWeight:"600" }}>
                        Car Detailers
                      </strong>
                    </h2>
                    <p>
                      Our team consists of dedicated car enthusiasts—our
                      professional detailers—who combine expertise with a
                      passion for cars. Trained in advanced car care methods,
                      they ensure your vehicle receives exceptional care every
                      time.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="why-chooseus-one mt-4 mb-4">
                    <img
                      className="img-fluid"
                      src="images/whyicon2.svg"
                      alt="Why Choose Us Photo"
                    />
                    <h2>Premium Products</h2>
                    <p>
                      We exclusively use high-quality, eco-friendly products
                      designed to care for your vehicle and the environment. Our
                      solutions deliver exceptional results while promoting a
                      healthier, more sustainable future for your car and the
                      planet.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="why-chooseus-one">
                    <img
                      className="img-fluid"
                      src="images/whyicon3.svg"
                      alt="Why Choose Us Photo"
                    />
                    <h2>Convenience</h2>
                    <p>
                      Enjoy flexible scheduling and doorstep services, making it
                      effortless to keep your car in top-notch
                      condition—anytime, anywhere.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="At-fingertips-men">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12"
              data-aos="zoom-in"
              data-aos-duration={2000}
            >
              <div className="At-fingertips-left">
                <div className="heading-men p-0">
                  <img
                    className="app-icon-photo"
                    src="images/app-icon.png"
                    alt="App Icon"
                  />
                  <h2>At your doorstep, at your fingertips</h2>
                  <p className="pt-2">
                    Experience the most convenient, professional, and
                    eco-friendly car wash solution—just a tap away!
                  </p>
                  <div className="all-btn">
                    <div className="row mt-4 justify-content-center">
                      <div className="col-lg-2 play">
                        <div className="btn1 btn2">
                          <a href="https://play.google.com/store/apps/details?id=com.washwoosh.userapp&pcampaignid=web_share">
                            <img
                              className="img-fluid"
                              src="images/d-app1.png"
                            />
                          </a>
                        </div>

                        <div className="btn1 btn2">
                          <a href="https://apps.apple.com/in/app/washwoosh-car-care-app/id6738276020">
                            <img
                              className="img-fluid"
                              src="images/d-app2.png"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
