import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      <div className="footer">
        <div className="footer-one">
          <div className="footer-shap">
            <img
              className="img-fluid"
              src="images/footer-shap1.png"
              alt="Footer Shap"
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="footer-text-one">
                  <a href="/">
                    <img
                      className="img-fluid"
                     src="images/logo.png"
                      alt="Footer Logo"
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration={2000}
                    />
                  </a>
                  <p>
                  At WashWoosh, we’re dedicated to delivering top-quality car care. With personalized service, we ensure your vehicle stays in peak condition, drive after drive.
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="footer-text-two">
                  <h2>Company</h2>
                  <ul className="mt-3">
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={"/"}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      <li>
                        <a>Home </a>
                      </li>
                    </Link>

                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={"/how-it-works"}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      <li>
                        <a>How it works</a>
                      </li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={"/services"}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      <li>
                        <a>Services</a>
                      </li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={"/contact"}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      <li>
                        <a>Contact</a>
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="footer-text-two">
                  <h2>Our Links</h2>
                  <ul className="mt-3">
                    {/* <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={"/blogs"}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      <li>
                        <a>Blog</a>
                      </li>
                    </Link> */}
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={"/privacy-policy"}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      <li>
                        <a>Privacy Policy</a>
                      </li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={"/Refund-policy"}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      <li>
                        <a>Refund Policy</a>
                      </li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={"/terms"}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      <li>
                        <a>Terms &amp; Conditions</a>
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="footer-text-two">
                  <h2>About Payment</h2>
                  <div className="accepted-payment mt-3">
                    <h3>Accepted Payment Methods:</h3>
                    <ul className="payment-photo">
                      <li>
                        <a>
                          <img
                            className="img-fluid"
                            src="images/mastercard.svg"
                            alt="Payment Photo"
                          />
                        </a>
                      </li>
                      <li>
                        <a>
                          <img
                            className="img-fluid"
                            src="images/visa.svg"
                            alt="Payment Photo"
                          />
                        </a>
                      </li>
                      <li>
                        <a>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="rupee-sign"
                            className="svg-inline--fa fa-rupee-sign "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            color="#7ac1c1"
                          >
                            <path
                              fill="currentColor"
                              d="M0 64C0 46.3 14.3 32 32 32h80c79.5 0 144 64.5 144 144c0 58.8-35.2 109.3-85.7 131.7l51.4 128.4c6.6 16.4-1.4 35-17.8 41.6s-35-1.4-41.6-17.8L106.3 320H64V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V288 64zM64 256h48c44.2 0 80-35.8 80-80s-35.8-80-80-80H64V256zm256.5 16.4c-.9 6 0 8.7 .4 9.8c.4 1.1 1.4 2.6 4.2 4.9c7.2 5.7 18.7 10 37.9 16.8l1.3 .5c16 5.6 38.7 13.6 55.7 28.1c9.5 8.1 17.9 18.6 23.1 32.3c5.1 13.7 6.1 28.5 3.8 44c-4.2 28.1-20.5 49.3-43.8 60.9c-22.1 11-48.1 12.5-73.2 8l-.2 0 0 0c-9.3-1.8-20.5-5.7-29.3-9c-6-2.3-12.6-4.9-17.7-6.9l0 0c-2.5-1-4.6-1.8-6.3-2.5c-16.5-6.4-24.6-25-18.2-41.4s24.9-24.6 41.4-18.2c2.6 1 5.2 2 7.9 3.1l0 0c4.8 1.9 9.8 3.9 15.4 6c8.8 3.3 15.3 5.4 18.7 6c15.7 2.8 26.7 .8 32.9-2.3c5-2.5 8-6 9.1-13c1-6.9 .2-10.5-.5-12.3c-.6-1.7-1.8-3.6-4.5-5.9c-6.9-5.8-18.2-10.4-36.9-17l-3-1.1c-15.5-5.4-37-13-53.3-25.9c-9.5-7.5-18.3-17.6-23.7-31c-5.5-13.4-6.6-28-4.4-43.2c8.4-57.1 67-78 116.9-68.9c6.9 1.3 27.3 5.8 35.4 8.4c16.9 5.2 26.3 23.2 21.1 40.1s-23.2 26.3-40.1 21.1c-4.7-1.4-22.3-5.5-27.9-6.5c-14.6-2.7-25.8-.4-32.6 3.2c-6.3 3.3-8.9 7.6-9.5 12z"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                    <p>
                      For all Tax purposes in the India , <br />
                      Our GST Number is <span>08ABNCS7930A1Z8</span>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-two">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8 text-align-center">
                <div className="copyright-text">
                  <p>
                    © 2024 washwoosh. All Right Reserved. A Unit of{" "}
                    <a href="https://satellitekeyit.com/">
                      SatelliteKey IT Solutions Pvt Ltd
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="social-media-footer">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/washwooshIndia/">
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/washwooshindia/">
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/washwooshindia/">
                        <i className="fa-brands fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a href="https://x.com/washwooshindia">
                        <i className="fa-brands fa-x-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@WashWooshIndia">
                        <i className="fa-brands fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <a
          href="https://api.whatsapp.com/send/?phone=917374947373&text=Hello%21+Can+I+get+more+information+about+WashWoosh+-+The+Car+Care+App%3F&type=phone_number&app_absent=0"
          className="float"
          target="_blank"
        >
          <i className="fa-brands fa-whatsapp my-float"></i>
        </a>
        </div>

       
      </div>
    </div>
  );
};

export default Footer;
